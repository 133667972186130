@import "~bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Permanent+Marker&display=swap');

body {
    background: $white;
    color: $black;
    font-family: 'Montserrat', sans-serif;
    h1, h2, h3, h4, .nav-item {
        font-family: 'Permanent Marker', cursive;
    }
    .nav-item:hover {
        text-decoration: underline;
    }
}

[data-theme="dark"] {
    background-color: #111 !important;
    color: #eee;
  }
  
  [data-theme="dark"] .bg-light {
    background-color: #333 !important;
  }
  
  [data-theme="dark"] .bg-white {
    background-color: #000 !important;
  }
  
  [data-theme="dark"] .bg-black {
    background-color: #eee !important;
  }

.dark-mode {
    background: $dark;
    color: $white;
}